import { ProductName } from '@/components/product-name'
import { GiftVariants } from '@/components/variants'
import { GiftCardProductAmountFragment } from '@/api'
import { GiftCardProductModalData } from '@/common/types/product-types'
import { PurchaseDialogImage } from './purchase-dialog-image'

export type Props = {
  productData: GiftCardProductModalData
  onClose?: () => void
}

export function PurchaseDialogGiftCardVariants({
  productData,
  onClose,
}: Props): JSX.Element {
  return (
    <div className="mb-4 text-left">
      <PurchaseDialogImage productData={productData} />

      <ProductName size="lg" dialogVariant>
        {productData?.name}
      </ProductName>

      <GiftVariants
        productName={productData?.name ?? ''}
        sku={productData?.sku ?? ''}
        giftCardAmounts={
          (productData?.giftcard_amounts as GiftCardProductAmountFragment[]) ??
          []
        }
        isPopupVariant
        onClose={onClose}
      />
    </div>
  )
}
