'use client'

import React, { useEffect, useMemo, useRef } from 'react'

import { ProductStockStatus, useProductModalDataQuery } from '@/api'
import { ProductDetailStockSubscribe } from '@/modules/product/components/detail/components/stock-subscribe'
import { PurchaseDialogConfigurableVariants } from './purchase-dialog-configurable-variants'
import { PurchaseDialogGiftCardVariants } from './purchase-dialog-gift-card-variants'
import {
  ConfigurableProductModalData,
  GiftCardProductModalData,
  ProductListItem,
  ProductType,
} from '@/common/types/product-types'
import { PurchaseDialogConfigurableVariantsSkeleton } from './purchase-dialog-configurable-variants-skeleton'
import { ProductDataContextProvider } from '@/providers/product-data/product-data-context'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { ListItemProductType, getProductPrice } from '@/common/utils'
import { Modal } from '../modal/modal'
import { getPathnameWithoutLocale } from '@/common/utils/url-utils'
import { AddToCartEventHandlerParams } from '@/providers/cart/cart.types'
import { CloseIcon } from '../icons/svg'
import { useWindowSize } from '@/common/hooks/use-window-size'
import { cn } from '@/common/utils/lib'
import { getInitialVariant } from '../variants/utils/getInitialVariant'

export type PurchaseDialogProduct = ProductListItem & {
  productType?: ListItemProductType
  parentSku: string
}

export type PurchaseDialogProps = {
  productListData?: PurchaseDialogProduct
  isOpen: boolean
  recommendedSimpleIds?: string[]
  onClose?: () => void
  onAddToCartEvent?: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function PurchaseDialog({
  productListData,
  isOpen,
  onClose,
  recommendedSimpleIds,
  onAddToCartEvent,
}: PurchaseDialogProps): JSX.Element | null {
  const isLongTermUnavailable = productListData?.longTermUnavailable !== 0
  const gtmLock = useRef<boolean>(false)

  const inStock = useMemo(
    () =>
      !isLongTermUnavailable ||
      productListData?.productType === ListItemProductType.GiftCard,
    [isLongTermUnavailable, productListData],
  )

  const { data, isLoading } = useProductModalDataQuery({
    url: getPathnameWithoutLocale(
      productListData?.canonicalUrl?.split('?')[0] || '',
    ),
  })

  const productData = data?.route as
    | ConfigurableProductModalData
    | GiftCardProductModalData

  useEffect(() => {
    if (productData && !isLoading && gtmLock.current === false) {
      const configurableVariant = getInitialVariant(
        productData,
        recommendedSimpleIds,
      )

      const { finalPrice } = getProductPrice({
        price_range:
          configurableVariant?.product?.price_range ?? productData.price_range,
        msrp_price:
          configurableVariant?.product?.msrp_price ?? productData.msrp_price,
      })

      gtmTracker.trackProductVariantsDetail({
        productData: {
          id: String(productData.id),
          name: productData.name ?? '',
          brand:
            productData.__typename === ProductType.Configurable &&
            productData.manufacturer_info
              ? productData.manufacturer_info?.name ?? ''
              : 'GymBeam',
          category: productData.breadcrumb_en ?? '',
        },
        price: finalPrice?.value ?? NaN,
        currency: finalPrice?.currency ?? '',
        simpleId: String(configurableVariant?.product?.id ?? ''),
      })
      gtmLock.current = true
    }
  }, [recommendedSimpleIds, productListData, productData, isLoading])

  const { width = 0, height = 0 } = useWindowSize()
  const isMobileView = width <= 768 || height <= 768
  const outOfStock = productData?.stock_status === ProductStockStatus.OutOfStock

  const recommendedSimpleId = useMemo(() => {
    if (!productData || !recommendedSimpleIds) {
      return undefined
    }

    const configurableVariant = getInitialVariant(
      productData,
      recommendedSimpleIds,
    )

    if (configurableVariant?.product?.id) {
      return String(configurableVariant?.product?.id)
    }

    return undefined
  }, [productData, recommendedSimpleIds])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      className={cn(
        'w-[500px] md:my-auto mt-0 overflow-auto max-h-full',
        isMobileView && 'md:mt-0',
      )}
    >
      <CloseIcon
        className="absolute right-0 top-0 mt-5 mr-5 w-5 h-5 cursor-pointer"
        onClick={onClose}
      />

      {productListData && productData ? (
        inStock ? (
          <>
            {productListData.productType ===
              ListItemProductType.Configurable && (
              <ProductDataContextProvider
                productSku={productData.sku}
                outOfStock={outOfStock}
                configurableVariants={
                  (productData as ConfigurableProductModalData)
                    .configurable_variants
                }
                giftCardAmounts={[]}
              >
                <PurchaseDialogConfigurableVariants
                  productData={productData as ConfigurableProductModalData}
                  isInStock={inStock}
                  isLongTermUnavailable={isLongTermUnavailable}
                  onClose={onClose}
                  recommendedSimpleId={recommendedSimpleId}
                  onAddToCartEvent={onAddToCartEvent}
                />
              </ProductDataContextProvider>
            )}
            {productListData.productType === ListItemProductType.GiftCard && (
              <ProductDataContextProvider
                productSku={productData.sku}
                outOfStock={outOfStock}
                configurableVariants={[]}
                giftCardAmounts={
                  (productData as GiftCardProductModalData).giftcard_amounts
                }
              >
                <PurchaseDialogGiftCardVariants
                  productData={productData as GiftCardProductModalData}
                  onClose={onClose}
                />
              </ProductDataContextProvider>
            )}
          </>
        ) : (
          <ProductDetailStockSubscribe />
        )
      ) : (
        <PurchaseDialogConfigurableVariantsSkeleton />
      )}
    </Modal>
  )
}
