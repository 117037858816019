'use client'

import { useTranslations } from 'next-intl'

import { cn } from '@/common/utils/lib'
import { Currency } from '@/components/currency'
import { Tooltip } from '@/components/tooltip'
import { selectors } from '@/common/constants/selectors-constants'

type PriceDisplayProps = {
  finalPrice: {
    value: number
    currency?: string
  } | null
  small?: boolean
  isB2B: boolean
  wholesaleDiscountPercentageFormatted: string
}

export function PriceDisplay({
  finalPrice,
  small,
  isB2B,
  wholesaleDiscountPercentageFormatted,
}: PriceDisplayProps) {
  const t = useTranslations('Product')

  return (
    <div data-test={selectors.PDP.productPrice} className="flex items-center">
      <Currency
        className={cn(
          'text-secondary',
          small ? 'text-[15px]' : 'text-lg',
          'font-bold leading-none',
        )}
        price={finalPrice?.value}
      />
      {isB2B && (
        <div className="cursor-default">
          <Tooltip
            direction="right"
            text={
              <span className="min-w-fit text-tooltip-text text-xs whitespace-nowrap">
                {t('detail.price.discount.wholesaleDiscount')}
              </span>
            }
          >
            <SaleRibbon
              wholesaleDiscountPercentageFormatted={
                wholesaleDiscountPercentageFormatted
              }
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

const SaleRibbon = ({
  wholesaleDiscountPercentageFormatted,
}: {
  wholesaleDiscountPercentageFormatted: string
}) => {
  return (
    <div
      className={`flex items-center justify-center
                  relative w-[65px] h-[24px] ml-3 rounded-[4px]
                 bg-secondary text-md font-bold text-[white]
                  before:absolute before:w-[18px] before:h-[18px]
                  before:rotate-45 before:-left-[7px] before:bg-secondary before:rounded-sm`}
    >
      <span>- {wholesaleDiscountPercentageFormatted}</span>
    </div>
  )
}
