'use client'

import { useQuery } from '@tanstack/react-query'

import { ProductDetailStorageBadges } from './badges'
import { Price } from './price/price'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'
import { clientFetch } from '@/common/api/rest/fetch.client'

interface ProductDetailStorageProps {
  isConfigurableProduct: boolean
  isGiftCardProduct: boolean
  inStock: boolean
  longTermUnavailable: boolean
  small?: boolean
}

export const ProductDetailStorage = ({
  isConfigurableProduct,
  isGiftCardProduct,
  inStock,
  longTermUnavailable,
  small,
}: ProductDetailStorageProps) => {
  const { data: deliveryData, isLoading: isLoadingDeliveryInfo } = useQuery({
    queryKey: ['deliveryData'],
    queryFn: async () => {
      const response = await clientFetch<DeliveryData>({
        url: '/api/v1/delivery-date-info',
      })

      return response
    },
  })

  if (small) {
    return (
      <div className="flex flex-col text-left">
        {isConfigurableProduct && !longTermUnavailable && (
          <Price
            deliveryData={deliveryData}
            isLoadingDeliveryInfo={isLoadingDeliveryInfo}
            inStock={inStock}
            small
          />
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex order-2 md:order-1">
        <ProductDetailStorageBadges
          deliveryData={deliveryData}
          inStock={inStock}
          longTermUnavailable={longTermUnavailable}
          isGiftCardProduct={isGiftCardProduct}
        />
      </div>
      {isConfigurableProduct && !longTermUnavailable && (
        <div className="flex-col order-1 md:order-1">
          <Price
            deliveryData={deliveryData}
            isLoadingDeliveryInfo={isLoadingDeliveryInfo}
            inStock={inStock}
          />
        </div>
      )}
    </div>
  )
}
