'use client'

import { useEffect, useRef, useState } from 'react'

import { Alert } from '@/components/alert'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import { useFlashMessagesContext } from '@/providers'
import { cn } from '@/common/utils/lib'

export function ProductFlashMessages(): JSX.Element {
  const { flashMessages } = useFlashMessagesContext()
  const isMd = useBrowserMediaQuery({ breakpoint: 'md' })
  const ref = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const elementPosition = ref.current.getBoundingClientRect().top
        const header =
          document.getElementById('header-id')?.offsetHeight ?? 1000000
        const topPosition = isMd ? header : 0
        const notVisibleBelowNotificationArea = elementPosition <= topPosition
        const notVisibleAboveNotificationArea =
          elementPosition >= window.innerHeight

        if (
          notVisibleBelowNotificationArea ||
          notVisibleAboveNotificationArea
        ) {
          setIsSticky(true)
          ref.current.style.top = `${topPosition}px`
        } else {
          setIsSticky(false)
        }
      }
    }

    if (flashMessages.length) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isMd, flashMessages])

  return (
    <div
      ref={ref}
      className={cn(
        'w-full flex flex-col gap-1',
        isSticky && `fixed left-0 z-10`,
      )}
    >
      {flashMessages.map((message) => (
        <div
          key={message.id}
          className="z-50 animate-flashMessage [&_a]:text-secondary hover:[&_a]:text-secondary-hover"
        >
          <Alert severity={message.severity}>
            <span dangerouslySetInnerHTML={{ __html: message.text }} />
          </Alert>
        </div>
      ))}
    </div>
  )
}
