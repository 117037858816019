import { UnitPrice as UnitPriceType } from '@/api'
import { getFormattedPrices } from '@/common/utils/price-utils'
import { useStoreContext } from '@/providers'

interface UnitPriceProps {
  unitPrice: UnitPriceType
}

// TODO: this will be later refactored to use the new API (at the moment it is compatible with both old one and new one)

export const UnitPrice = ({ unitPrice }: UnitPriceProps) => {
  const {
    storeCode,
    storeConfig: { dualCurrency },
  } = useStoreContext()
  const unit = unitPrice?.unit?.split('/')[1]

  const { mainPrice, localPrice } = getFormattedPrices({
    price: {
      value: unitPrice.value,
      currency: unitPrice.currency,
    },
    storeCode,
    dualCurrency,
  })

  return (
    <span className="text-xs">
      {mainPrice}
      {dualCurrency?.format && localPrice && (
        <span className="text-grey-300 font-normal">≈({localPrice})</span>
      )}
      /{unit || unitPrice.unit}
    </span>
  )
}
