import { useTranslations } from 'next-intl'

export default function VatAndShippingMessage({
  hasFreeShipping,
}: {
  hasFreeShipping: boolean
}) {
  const t = useTranslations('Product.detail.shipping')

  return (
    <div className="text-xs">
      {hasFreeShipping ? t('vatAndFreeShipping') : t('vatAndPaidShipping')}
    </div>
  )
}
