import { ProductName } from '@/components/product-name'
import { selectors } from '@/common/constants/selectors-constants'
import { ProductDetailStorage } from '@/modules/product/components/detail/components/storage/product-detail-storage'
import { ConfigurableProductModalData } from '@/common/types/product-types'
import { ConfigurableVariants } from '../variants'
import { AddToCartEventHandlerParams } from '@/providers/cart/cart.types'
import { PurchaseDialogImage } from './purchase-dialog-image'

export type Props = {
  isLongTermUnavailable: boolean
  isInStock: boolean
  productData: ConfigurableProductModalData
  onClose?: () => void
  recommendedSimpleId?: string
  onAddToCartEvent?: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function PurchaseDialogConfigurableVariants({
  isLongTermUnavailable,
  isInStock,
  productData,
  onClose,
  recommendedSimpleId,
  onAddToCartEvent,
}: Props): JSX.Element {
  return (
    <div className="mb-4 text-left">
      <PurchaseDialogImage />

      <ProductName size="lg" dialogVariant data-test={selectors.CP.productName}>
        {productData.name}
      </ProductName>

      <div className="-mt-6 mb-1">
        <ProductDetailStorage
          isConfigurableProduct
          longTermUnavailable={isLongTermUnavailable}
          isGiftCardProduct={false}
          inStock={isInStock}
          small
        />
      </div>

      <ConfigurableVariants
        configurableProductData={productData}
        isPopupVariant
        onClose={onClose}
        recommendedSimpleId={recommendedSimpleId}
        onAddToCartEvent={onAddToCartEvent}
      />
    </div>
  )
}
