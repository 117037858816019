'use client'

import { useTranslations } from 'next-intl'
import Link from 'next/link'

import { cn } from '@/common/utils/lib'
import { isDualCurrency } from '@/common/utils/store.exceptions'
import { StoreCodeType } from '@/common/types'

type FreeReturnsLinkProps = {
  storeCode: string
}

export function FreeReturnsLink({ storeCode }: FreeReturnsLinkProps) {
  const t = useTranslations('Product')

  return (
    <Link
      href={t('detail.price.delivery.freeReturnsLink')}
      className={cn(
        'text-grey-700 text-xs underline',
        isDualCurrency(storeCode as StoreCodeType) ? 'text-right' : '',
      )}
    >
      {t('detail.price.delivery.freeReturns')}
    </Link>
  )
}
