'use client'
import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { Currency } from '@/components/currency'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Tooltip } from '@/components/tooltip'
import { useAuthContext, useStoreContext } from '@/providers'
import { getProductPrice } from '@/utils'
import { DeliveryInfo } from './delivery-info'
import { UnitPrice } from './unit-price'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/product-data-context'
import VatAndShippingMessage from './vat-and-shipping-message'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'
import {
  isDualCurrency,
  hasVatShippingMessage,
  STORES_EXCLUDED_FROM_FREE_RETURN,
} from '@/common/utils/store.exceptions'
import { FreeReturnsLink } from './free-returns-link'
import { PriceDisplay } from './price-display'
import { LoadingSkeleton } from './loading-skeleton'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import { cn } from '@/common/utils/lib'

type PriceProps = {
  inStock: boolean
  deliveryData?: DeliveryData | null
  small?: boolean
  isLoadingDeliveryInfo?: boolean
}

export function Price({
  deliveryData,
  inStock,
  small,
  isLoadingDeliveryInfo,
}: PriceProps): JSX.Element | null {
  const t = useTranslations('Product')
  const { customerData } = useAuthContext()
  const { configurableProductVariant } = useProductDataContext()
  const { storeCode } = useStoreContext()
  const {
    showRegularPrice,
    recommendedPrice,
    finalPrice,
    wholesaleDiscountPercentageFormatted,
  } = getProductPrice({
    price_range: configurableProductVariant?.product?.price_range,
    msrp_price: configurableProductVariant?.product?.msrp_price,
  })

  const unitPrice = configurableProductVariant?.product?.unit_price

  const isB2B = !!customerData?.is_b2b

  const isMaxLg = useBrowserMediaQuery({ breakpoint: 'lg' })
  const shouldUseDualCurrencyLayout = isDualCurrency(storeCode) || isMaxLg

  const shouldShowFreeReturns =
    !STORES_EXCLUDED_FROM_FREE_RETURN.includes(storeCode)

  const getTooltipText = (isB2B: boolean) => {
    return isB2B
      ? t('detail.price.discount.recommendedRetailPrice')
      : t('detail.price.lowestLastPrice')
  }

  const renderPriceDisplay = () => (
    <div data-test={selectors.PDP.productPrice} className="flex items-center">
      <Currency
        className={cn(
          'text-secondary',
          small ? 'text-sm' : 'text-lg',
          'font-bold leading-none',
        )}
        price={finalPrice?.value}
      />
      {isB2B && (
        <div className="cursor-default">
          <Tooltip
            direction="right"
            text={
              <span className="min-w-fit text-tooltip-text text-xs whitespace-nowrap">
                {t('detail.price.discount.wholesaleDiscount')}
              </span>
            }
          >
            <SaleRibbon
              wholesaleDiscountPercentageFormatted={
                wholesaleDiscountPercentageFormatted
              }
            />
          </Tooltip>
        </div>
      )}
    </div>
  )

  const renderRegularPriceWithTooltip = () => (
    <div className="text-grey-300 text-xs flex items-center">
      <Currency
        className="line-through"
        currency={recommendedPrice?.currency}
        price={recommendedPrice?.value}
      />
      <span className="cursor-help inline-flex ml-1">
        <Tooltip
          direction="right"
          text={
            <span className="w-[210px] inline-block text-tooltip-text">
              {getTooltipText(isB2B)}
            </span>
          }
        >
          <QuestionMarkRound className="fill-grey-300" width={18} height={18} />
        </Tooltip>
      </span>
    </div>
  )

  const renderDeliverySection = () => (
    <div
      className={cn(
        'flex flex-col',
        shouldUseDualCurrencyLayout ? '' : 'items-end mt-1',
      )}
    >
      <DeliveryInfo
        deliveryData={deliveryData}
        productPrice={finalPrice?.value}
        hasVatShippingMessage={hasVatShippingMessage(storeCode)}
      />
      {shouldShowFreeReturns && <FreeReturnsLink storeCode={storeCode} />}
    </div>
  )

  const renderVatAndShippingMessage = () => {
    if (!hasVatShippingMessage(storeCode)) return null

    return (
      <VatAndShippingMessage
        hasFreeShipping={hasFreeShipping(
          finalPrice?.value,
          deliveryData?.freeShippingThreshold,
        )}
      />
    )
  }

  const renderPriceSection = () => {
    if (shouldUseDualCurrencyLayout) {
      return (
        <div className="flex flex-col">
          <div>
            <PriceDisplay
              finalPrice={
                finalPrice
                  ? {
                      value: finalPrice.value ?? 0,
                      currency: finalPrice.currency ?? undefined,
                    }
                  : null
              }
              small={small}
              isB2B={isB2B}
              wholesaleDiscountPercentageFormatted={
                wholesaleDiscountPercentageFormatted
              }
            />
            {showRegularPrice && renderRegularPriceWithTooltip()}
            {unitPrice && <UnitPrice unitPrice={unitPrice} />}
            {renderVatAndShippingMessage()}
          </div>
          <div className="mt-2">
            {isLoadingDeliveryInfo ? (
              <div className="flex flex-col items-end">
                <LoadingSkeleton />
              </div>
            ) : (
              renderDeliverySection()
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="flex justify-between items-start">
        <div>
          {renderPriceDisplay()}
          {renderVatAndShippingMessage()}
          {showRegularPrice && renderRegularPriceWithTooltip()}
          {unitPrice && <UnitPrice unitPrice={unitPrice} />}
        </div>

        <div
          className={cn(
            'flex flex-col',
            hasVatShippingMessage(storeCode) ? 'mt-5' : 'mt-0',
          )}
        >
          {isLoadingDeliveryInfo ? (
            <div className="flex flex-col items-end">
              <LoadingSkeleton />
            </div>
          ) : (
            renderDeliverySection()
          )}
        </div>
      </div>
    )
  }

  if (inStock) {
    return <div className="my-4">{renderPriceSection()}</div>
  } else if (finalPrice) {
    return (
      <div className="mt-2 price-estimated">
        <span className={twMerge('label', 'text-lg font-bold')}>
          {t('detail.price.expectedPrice')}&nbsp;
        </span>
        <Currency
          className="text-secondary text-lg font-bold"
          price={finalPrice?.value}
        />
      </div>
    )
  } else {
    return null
  }
}

const SaleRibbon = ({
  wholesaleDiscountPercentageFormatted,
}: {
  wholesaleDiscountPercentageFormatted: string
}) => {
  return (
    <div
      className={`flex items-center justify-center
                  relative w-[65px] h-[24px] ml-3 rounded-[4px]
                 bg-secondary text-md font-bold text-[white]
                  before:absolute before:w-[18px] before:h-[18px]
                  before:rotate-45 before:-left-[7px] before:bg-secondary before:rounded-sm`}
    >
      <span>- {wholesaleDiscountPercentageFormatted}</span>
    </div>
  )
}

export const hasFreeShipping = (
  price?: number | null,
  thresholdPrice?: number,
): boolean => {
  if (price === undefined || price === null || thresholdPrice === undefined)
    return false
  return price >= thresholdPrice
}
