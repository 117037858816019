import { Image } from '@/common/components/image/image'
import type { ProductLabelV2Fragment } from '@/api'

export default function ImageLabel({
  src,
  content,
  label,
}: ProductLabelV2Fragment) {
  return (
    <Image
      key={label}
      className="h-full w-full object-contain object-center"
      src={src ?? ''}
      alt={content ?? ''}
      width={400}
      height={400}
      unoptimized
    />
  )
}
