import { DeliveryData } from '@/app/api/delivery-date-info/route'

export const getCheapestDeliveryPrice = (
  deliveryData?: DeliveryData | null,
) => {
  return deliveryData?.methods.length
    ? Math.min(
        ...deliveryData?.methods
          .filter((item) => item.price !== 0)
          .map((item) => item.price),
      )
    : null
}

type DetermineFreeShippingProps = {
  sumOfItemsInCart: number
  productPrice?: number | null
  freeShippingThreshold?: number | null
}

export const determineFreeShipping = ({
  sumOfItemsInCart,
  productPrice,
  freeShippingThreshold,
}: DetermineFreeShippingProps) => {
  const freeShippingThresholdFallback = freeShippingThreshold ?? Infinity

  return (
    sumOfItemsInCart >= freeShippingThresholdFallback ||
    (productPrice && productPrice >= freeShippingThresholdFallback)
  )
}
