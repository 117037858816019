import { useProductDataContext } from '@/providers/product-data/product-data-context'
import { Image } from '@/common/components/image/image'
import { GiftCardProductModalData } from '@/common/types/product-types'

export function PurchaseDialogImage({
  productData,
}: {
  productData?: GiftCardProductModalData
}) {
  const { configurableProductVariant } = useProductDataContext()
  const image =
    productData?.image?.url?.full ??
    configurableProductVariant?.product?.image?.url?.full
  const alt = productData?.name ?? configurableProductVariant?.product?.name

  return (
    <Image
      src={image ?? ''}
      alt={alt ?? ''}
      priority
      width={500}
      height={500}
      className="object-contain w-full h-[215px]"
      data-test="variant-image"
    />
  )
}
