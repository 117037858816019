import { useTranslations } from 'next-intl'

import { Currency } from '@/components/currency'
import { useCartContext } from '@/providers'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'
import { isGeneralMarket } from '@/common/utils/store.exceptions'
import {
  determineFreeShipping,
  getCheapestDeliveryPrice,
} from './delivery-info.utils'
import { useStoreContext } from '@/providers'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import { cn } from '@/common/utils/lib'
import { isDualCurrency } from '@/common/utils/store.exceptions'

type Props = {
  deliveryData?: DeliveryData | null
  productPrice: number | null | undefined
  hasVatShippingMessage: boolean
}

export const DeliveryInfo = ({ deliveryData, productPrice }: Props) => {
  const t = useTranslations('Product')
  const { cartItems, isCartRefetching } = useCartContext()
  const { storeCode } = useStoreContext()
  const isMaxLg = useBrowserMediaQuery({ breakpoint: 'lg' })

  const isStoreDualCurrency = isDualCurrency(storeCode)
  const shouldUseDualCurrencyLayout = isStoreDualCurrency || isMaxLg

  const cheapestDeliveryPrice = getCheapestDeliveryPrice(deliveryData)
  const freeShippingThreshold = deliveryData?.freeShippingThreshold
  const hasCheapestDeliveryPrice = !!cheapestDeliveryPrice

  const totalSum = cartItems.reduce((acc, item) => {
    return acc + (item?.prices?.row_total_including_tax?.value || 0)
  }, 0)

  const canHideDeliveryInfo =
    freeShippingThreshold === null ||
    isCartRefetching ||
    (!hasCheapestDeliveryPrice && !isGeneralMarket(storeCode))

  if (canHideDeliveryInfo) {
    return null
  }

  const isDeliveryFree = determineFreeShipping({
    sumOfItemsInCart: totalSum,
    productPrice,
    freeShippingThreshold,
  })

  if (isDeliveryFree) {
    return (
      <div
        className={cn(
          'text-xs text-grey-700',
          !shouldUseDualCurrencyLayout && 'text-right',
        )}
      >
        <div>{t('detail.price.delivery.free')}</div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'text-xs text-grey-700',
        !shouldUseDualCurrencyLayout && 'text-right',
      )}
    >
      {!isGeneralMarket(storeCode) && (
        <div>
          {t('detail.price.delivery.from')}{' '}
          <Currency price={cheapestDeliveryPrice} />
        </div>
      )}
      <div>
        {t('detail.price.delivery.freeFrom')}{' '}
        <Currency price={freeShippingThreshold} />
      </div>
    </div>
  )
}
